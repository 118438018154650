import React from "react";

import "./Header.css";

const Header = () => {
  return (
    <header>
      <h2>DOOM</h2>

      <div>
        <ul>
          <li>
            <a href='/home'>
              <h4>home</h4>
            </a>
          </li>
          <li>
            <a href='/library'>
              <h4>library</h4>
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
