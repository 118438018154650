import React, { useRef, useEffect } from "react";
import "./Canvas.css";

const Canvas = () => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const canvas = ref.current;
    const context = canvas.getContext("2d");

    // canvas.height = ref.current.clientHeight;
    // canvas.width = ref.current.clientWidth;
    canvas.height = 1080;
    canvas.width = 1920;

    let painting = false;

    const startPosition = (event) => {
      painting = true;
      draw(event);
    };

    const finishedPosition = () => {
      painting = false;
      context.beginPath();
    };

    const draw = (event) => {
      if (!painting) {
        return;
      }

      const bounds = canvas.getBoundingClientRect();
      let mouseX = event.pageX - bounds.left;
      let mouseY = event.pageY - bounds.top;

      mouseX /= bounds.width;
      mouseY /= bounds.height;

      mouseX *= canvas.width;
      mouseY *= canvas.height;

      context.lineWidth = 10;
      context.lineCap = "round";

      context.lineTo(mouseX, mouseY);
      context.stroke();
      context.beginPath();
      context.moveTo(mouseX, mouseY);
    };

    canvas.addEventListener("mousedown", startPosition);
    canvas.addEventListener("mouseup", finishedPosition);
    canvas.addEventListener("mousemove", draw);

    return () => {
      canvas.removeEventListener("mousedown", startPosition);
      canvas.removeEventListener("mouseup", finishedPosition);
      canvas.removeEventListener("mousemove", draw);
    };
  }, []);

  return <canvas ref={ref}></canvas>;
};

export default Canvas;
