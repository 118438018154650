import "./App.css";

//components
import Header from "./components/Header";
import ToolBox from "./components/ToolBox";
import Canvas from "./components/Canvas";
import ScrubSpace from "./components/ScrubSpace";

function App() {
  return (
    <div className='App'>
      <Header />
      <main>
        <ToolBox />
        <section id='create-space'>
          <Canvas />
          <ScrubSpace />
        </section>
      </main>
    </div>
  );
}

export default App;
